import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const Forte = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #B9A013, #DABD15, #EDCD16, #DABD15, #DABD15 )",
        icons: "#fed412",
        navClass: "forte",
      }}
      seo={{
        title: "Forte",
        headerTitle: "forte",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/forte_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/forte/",
      }}
    >
      <PortfolioHeader name="forte" height="278" />
      <section className="container-fluid forte_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Forte</h1>
              <ul>
                <li>Sklep internetowy</li>
                <li>Praca konkursowa</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                FORTE jest jednym z największych europejskich producentów mebli
                do samodzielnego montażu. Meble projektowane są z pasją przez
                polskich i zagranicznych projektantów a design jest dostosowany
                do zmieniających się trendów rynkowych i oczekiwań najbardziej
                wymagających klientów.
              </p>
              <h3>Cel:</h3>
              <ol>
                <li>Wykonanie nowego projektu sklepu internetowego.</li>
                <li>
                  Prezentacja marki jako nowoczesnej firmy, <br /> lidera rynku
                  w Polsce i Europie
                </li>
              </ol>
              <h3>Efekt</h3>
              <p>
                Zaprojektowaliśmy wygodny i skuteczny sklep internetowy w
                oparciu o istniejącą identyfikację wizualną marki. Projekt nie
                został zrealizowany, ponieważ Klient zrezygnował z decyzji o
                zmianie sklepu internetowego.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid forte_section_3">
        <div className="row row_1">
          <div className="col-md-6 offset-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/forte_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row row_2">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/forte_main_2.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row row_3">
          <div className="col-md-7 offset-md-5 text-right">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/forte_main_3.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row row_4">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/forte_main_4.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row row_5">
          <div className="col-md-3 offset-md-9 text-right">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/forte_main_5.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Forte;
